var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, width: "450" },
      on: {
        "click:outside": function ($event) {
          return _vm.$emit("closeEditNoteDialog")
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.selectedOrder.product_order_number
                    ? `Order ${_vm.selectedOrder.product_order_number} Information`
                    : "Order Information"
                ) +
                " "
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c("v-textarea", {
                attrs: {
                  label: "Note",
                  "background-color": "input",
                  rows: "3",
                  dense: "",
                  clearable: "",
                  "hide-details": "",
                  outlined: "",
                },
                model: {
                  value: _vm.orderNote,
                  callback: function ($$v) {
                    _vm.orderNote = $$v
                  },
                  expression: "orderNote",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "grey darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeEditNoteDialog")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.update } },
                [_vm._v(" Update ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }