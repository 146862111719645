var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    attrs: {
      label: "Search Filters",
      items: _vm.filters,
      disabled: _vm.disabled,
      "item-text": "name",
      "item-value": "key",
      "background-color": "input",
      "menu-props": { offsetY: true, maxHeight: "600" },
      multiple: "",
      outlined: "",
      "hide-details": "",
      "small-chips": "",
      dense: "",
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ attrs, item }) {
          return [
            item.key === "item_sources" && _vm.params.item_sources.length > 0
              ? _vm._l(_vm.selectedSources, function (source) {
                  return _c(
                    "v-chip",
                    { key: source.source_id, attrs: { small: "" } },
                    [
                      _vm._v(" " + _vm._s(source.display_name) + " "),
                      _c(
                        "v-icon",
                        {
                          attrs: { small: "", right: "" },
                          on: {
                            click: function ($event) {
                              return _vm.removeSource(source)
                            },
                          },
                        },
                        [_vm._v(" $delete ")]
                      ),
                    ],
                    1
                  )
                })
              : _c(
                  "v-chip",
                  _vm._b({ attrs: { small: "" } }, "v-chip", attrs, false),
                  [
                    _vm._v(" " + _vm._s(_vm.getFilterText(item)) + " "),
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "", right: "" },
                        on: {
                          click: function ($event) {
                            return _vm.removeFilter(item.key, true)
                          },
                        },
                      },
                      [_vm._v(" $delete ")]
                    ),
                  ],
                  1
                ),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item, attrs }) {
          return [
            _c(
              "v-list-item",
              [
                _c(
                  "v-list-item-content",
                  [
                    item.key === "item_sources"
                      ? _c("v-autocomplete", {
                          attrs: {
                            label: "Item Sources",
                            items: _vm.userSources,
                            "item-text": "display_name",
                            "item-value": "source_id",
                            multiple: "",
                            "deletable-chips": "",
                            "small-chips": "",
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "return-object": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.selectedSources,
                            callback: function ($$v) {
                              _vm.selectedSources = $$v
                            },
                            expression: "selectedSources",
                          },
                        })
                      : ["category", "department"].includes(item.key)
                      ? _c("v-autocomplete", {
                          attrs: {
                            items: _vm.keyItemMap[item.key],
                            label: item.name,
                            dense: "",
                            "hide-details": "",
                            outlined: "",
                            clearable: "",
                          },
                          on: {
                            "click:clear": function ($event) {
                              return _vm.removeFilter(item.key)
                            },
                            change: function ($event) {
                              return _vm.handleInputValue(item)
                            },
                          },
                          model: {
                            value: _vm.params[`${item.key}`],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, `${item.key}`, $$v)
                            },
                            expression: "params[`${item.key}`]",
                          },
                        })
                      : _c("v-text-field", {
                          attrs: {
                            label: item.name,
                            "background-color": "input",
                            prefix: item.prefix || null,
                            type: item.type,
                            dense: "",
                            clearable: "",
                            "hide-details": "",
                            outlined: "",
                          },
                          on: {
                            input: function ($event) {
                              attrs.inputValue
                                ? null
                                : _vm.handleInputValue(item)
                            },
                            "click:clear": function ($event) {
                              return _vm.removeFilter(item.key)
                            },
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.updateSearchFilters(true)
                            },
                          },
                          model: {
                            value: _vm.params[`${item.key}`],
                            callback: function ($$v) {
                              _vm.$set(_vm.params, `${item.key}`, $$v)
                            },
                            expression: "params[`${item.key}`]",
                          },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedFilters,
      callback: function ($$v) {
        _vm.selectedFilters = $$v
      },
      expression: "selectedFilters",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }