<template>
    <v-dialog :value="value" width="450" @click:outside="$emit('closeEditNoteDialog')">
      <v-card>
        <v-card-title>
            {{selectedOrder.product_order_number ? `Order ${selectedOrder.product_order_number} Information` : "Order Information"}}
        </v-card-title>
        <v-card-text>
            <v-textarea
              v-model="orderNote"
              label="Note"
              background-color="input"
              rows="3"
              dense
              clearable
              hide-details
              outlined
            >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey darken-1" text @click="$emit('closeEditNoteDialog')">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="update">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { userAccess } from '@/mixins/user-access'

  export default {
    name: 'InfoDialog',
    data() {
      return {
        orderNote: this.selectedOrder.note || ""
      }
    },
    mixins: [userAccess],
    props: {
      value: Boolean,
      selectedOrder: Object
    },
    methods: {
      update() {
        this.$emit('updateNote', this.orderNote)
        this.$emit('closeEditNoteDialog')
      }
    }
  }
  </script>