import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import qs from 'qs'

class Items {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
    this.items = base
  }

  async getSources () {
    await AuthToken.setAuth(this.items)
    return this.items({
      url: '/sources',
      method: 'GET'
    })
  }

  async getSourceRelsByPartyId (partyId) {
    await AuthToken.setAuth(this.items)
    return this.items({
      url: `/source/relationships/party/${partyId}`,
      method: 'GET'
    })
  }

  // 'exclude_non_price = true' filters out items without a match on the price table
  async searchItems (data = {}, offset = 0, limit = 50, is_retailer = false, exclude_non_price  = true) {
    await AuthToken.setAuth(this.items)
    return this.items({
      url: '/search',
      method: 'POST',
      params: { offset, limit, is_retailer, exclude_non_price  },
      data
    })
  }

  async getPossibleValues (column, sources = [], is_retailer = false) {
    const params = { sources, is_retailer }
    await AuthToken.setAuth(this.items)
    return this.items({
      url: `/possible_values/${column}`,
      method: 'GET',
      params: params,
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }
}

export default new Items()