<template>
    <v-dialog :value="value" width="450" @click:outside="$emit('closeCancelDialog')">
      <v-card>
        <v-card-title>
          {{selectedOrder.product_order_number ? `Cancel Order ${selectedOrder.product_order_number}` : "Cancel Order"}}?
        </v-card-title>
        <v-card-text>
            <v-textarea
              v-model="orderNote"
              label="Note"
              background-color="input"
              rows="3"
              dense
              clearable
              hide-details
              outlined
            >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey darken-1" text @click="$emit('closeCancelDialog')">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="confirm">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'CancelDialog',
    data() {
      return {
        orderNote: ""
      }
    },
    props: {
      value: Boolean,
      selectedOrder: Object
    },
    methods: {
      confirm() {
        this.$emit('cancel', this.orderNote)
        this.$emit('closeCancelDialog')
      }
    }
  }
  </script>