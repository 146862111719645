<template>
  <v-dialog :value="value" persistent width="400" @click:outside="close()">
    <v-card color="fill">
      <v-container>
        <v-card-title class="my-0 py-0 pt-0 mb-2 heading-6">
          <div class="text-no-wrap">
            Item ID: {{ item.item_id }}
          </div>
          <v-spacer/>
          <v-btn icon @click="close()">
            <v-icon dense>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ item.description }}
        </v-card-subtitle>
        <template v-if="loading">
          <v-progress-linear indeterminate color="primary" height="6" />
        </template>
        <template v-else>
          <v-row dense align="center" class="header mx-1 py-1">
            <v-col align="start" class="pl-2 ml-0">
              Submitted On
            </v-col>
            <v-col cols="auto" class="pl-0 mr-2">
              Qty
            </v-col>
          </v-row>
          <v-row dense class="mx-1 pb-1">
            <v-list v-if="historyItems.length > 0" dense class="pt-0" width="100%" style="max-height: 60vh; overflow-y: auto;">
              <template v-for="(historyItem, index) in historyItems">
                <div :key="historyItem.product_order_id">
                  <v-list-item class="ma-0 pl-2 py-1">
                    <v-row dense align="center" class="pl-0 ml-0">
                      <v-col align="start" class="pl-0 ml-0">
                        {{ $config.formatTimestamp(historyItem.submitted_on) }}
                      </v-col>
                      <v-col cols="auto" class="pl-0 mr-2">
                        {{ historyItem.quantity }}
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-divider 
                    v-if="index < historyItems.length - 1"
                  />
                </div>
              </template>
            </v-list>
            <v-row class="my-4" v-else dense>
              <v-col align="center">
                <p class="font-weight-light text-no-wrap">
                  No previous orders.
                </p>
              </v-col>
            </v-row>
          </v-row>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { utils } from '@/mixins/utils'
import { displayAlert } from '@/mixins/alert'
import ProductOrder from '@/api/product-order'
export default {
  name: 'OrderView',
  mixins: [displayHelpers, displayAlert, utils],
  props: [
    'value',
    'item',
    'party_id'
  ],
  data() {
    return {
      loading: false,
      historyItems: []
    }
  },
  async created() {
    this.loading = true
    await this.getHistory()
    this.loading = false
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getHistory() {
      try {
        const { data } = await ProductOrder.getPreviousOrderItem(this.item.item_id, this.party_id)
        if (data?.length > 0) {
          this.historyItems = data
        }
      } catch (err) {
        this.handleError(err)
      }
    }
  }
}
</script>
<style>
.header {
  color: white;
  background: #385F73;
}
</style>
