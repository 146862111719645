var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-2",
          attrs: { tile: "", flat: "", color: "grey lighten-3" },
        },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "grey lighten-3" } },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("mdi-clipboard-text"),
              ]),
              _c("v-toolbar-title", [_vm._v("Order Entry")]),
              _c("v-divider", {
                staticClass: "mx-4 d-none d-sm-flex",
                attrs: { inset: "", vertical: "" },
              }),
              _c(
                "v-col",
                {
                  staticClass: "text-truncate",
                  staticStyle: { "max-width": "430px" },
                  attrs: { cols: "auto", lg: "3" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      label: "Store",
                      items: _vm.stores,
                      loading: _vm.loading,
                      "item-text": "name",
                      "item-value": "id",
                      outlined: "",
                      dense: "",
                      "background-color": "input",
                      "hide-details": "auto",
                      "prepend-inner-icon": "mdi-store-outline",
                    },
                    on: {
                      "update:items": function ($event) {
                        _vm.stores = $event
                      },
                      change: _vm.handleChangedStore,
                    },
                    model: {
                      value: _vm.storeId,
                      callback: function ($$v) {
                        _vm.storeId = $$v
                      },
                      expression: "storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-none d-sm-flex text-truncate",
                  staticStyle: { "max-width": "430px" },
                  attrs: { lg: "4" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Customer Invoice Note (Optional)",
                      dense: "",
                      "validate-on-blur": "",
                      "background-color": "input",
                      outlined: "",
                      maxlength: "14",
                      "hide-details": "auto",
                    },
                    on: {
                      keyup: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleNoteInput(_vm.note)
                      },
                    },
                    model: {
                      value: _vm.note,
                      callback: function ($$v) {
                        _vm.note = $$v
                      },
                      expression: "note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "d-flex d-sm-none", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "px-4", attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-truncate" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Customer Invoice Note (Optional)",
                          dense: "",
                          "validate-on-blur": "",
                          "background-color": "input",
                          outlined: "",
                          maxlength: "14",
                          "hide-details": "auto",
                        },
                        on: {
                          keyup: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleNoteInput(_vm.note)
                          },
                        },
                        model: {
                          value: _vm.note,
                          callback: function ($$v) {
                            _vm.note = $$v
                          },
                          expression: "note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticStyle: { "border-top": "1px solid #ddd" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            { staticClass: "px-2", attrs: { dense: "", align: "center" } },
            [
              _c(
                "v-col",
                { staticStyle: { "max-width": "430px" } },
                [
                  _c("ItemSearchFilters", {
                    attrs: { disabled: _vm.loadingFilters },
                    on: { filterInput: _vm.handleFilterInput },
                    model: {
                      value: _vm.searchParams,
                      callback: function ($$v) {
                        _vm.searchParams = $$v
                      },
                      expression: "searchParams",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-auto d-none d-sm-flex",
                  attrs: { cols: "auto" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-weight-medium text-no-wrap text-h6 my-auto",
                    },
                    [_vm._v(" Est. Total: $" + _vm._s(_vm.totalCost) + " ")]
                  ),
                ]
              ),
              _c("v-divider", {
                staticClass: "mx-4 mb-2 d-none d-sm-flex",
                attrs: { inset: "", vertical: "" },
              }),
              _c(
                "v-col",
                { staticClass: "d-flex d-sm-none", attrs: { cols: "auto" } },
                [
                  _c(
                    "p",
                    { staticClass: "font-weight-medium text-no-wrap my-auto" },
                    [_vm._v(" Est. Total: $" + _vm._s(_vm.totalCost) + " ")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto", "align-self": "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: {
                        color: "main",
                        rounded: "",
                        text: "",
                        readonly: _vm.loading,
                        disabled: _vm.orderingDisabled || !_vm.activeOrder,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.cartOpen = !_vm.cartOpen
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-cart"),
                      ]),
                      _vm._v(" Cart "),
                      _c("v-badge", {
                        staticClass: "ml-2 mb-2",
                        attrs: {
                          color: "primary",
                          content: _vm.cartCount,
                          value: _vm.cartCount > 0,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { tile: "", flat: "" } },
        [
          _c("ItemSearchTable", {
            attrs: {
              storeSources: _vm.selectedSources,
              orderItems: _vm.orderItems,
              catalogName: _vm.catalogName,
              searchParams: _vm.searchParams,
              storeId: _vm.storeId,
              orderLoading: _vm.loading,
            },
            on: { add: _vm.addItem },
          }),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-navigation-drawer",
                {
                  staticStyle: { "z-index": "20" },
                  attrs: {
                    absolute: "",
                    right: "",
                    bottom: "",
                    width: _vm.cartOpen ? "800px" : "0",
                    temporary: "",
                    color: "grey lighten-3",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex pr-4",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-weight-medium text-no-wrap text-h6 mb-0",
                                },
                                [
                                  _vm._v(
                                    " Est. Order Total: $" +
                                      _vm._s(_vm.totalCost) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-auto",
                                  attrs: {
                                    color: "success",
                                    loading: _vm.loading,
                                    disabled:
                                      _vm.orderItems.length === 0 ||
                                      _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.confirmSubmit = true
                                    },
                                  },
                                },
                                [_vm._v(" Submit Order ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    disabled: !_vm.order.id || _vm.loading,
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.confirmDelete = true
                                    },
                                  },
                                },
                                [_vm._v(" Clear ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.cartOpen,
                    callback: function ($$v) {
                      _vm.cartOpen = $$v
                    },
                    expression: "cartOpen",
                  },
                },
                [
                  _c("OrderItemsTable", {
                    attrs: {
                      orderItems: _vm.orderItems,
                      orderLoading: _vm.loading,
                    },
                    on: {
                      updateItem: _vm.updateItem,
                      removeItem: _vm.removeItem,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.confirmSubmit
        ? _c("SubmitOrderDialog", {
            attrs: { submitting: _vm.submitting },
            on: { cancel: _vm.cancelSubmit, confirmSubmit: _vm.submitOrder },
            model: {
              value: _vm.confirmSubmit,
              callback: function ($$v) {
                _vm.confirmSubmit = $$v
              },
              expression: "confirmSubmit",
            },
          })
        : _vm._e(),
      _vm.confirmDelete
        ? _c("ConfirmDialog", {
            attrs: {
              title: "Clear Order",
              text: "Clear the current order? This will clear all items.",
            },
            on: { cancel: _vm.cancelDelete, confirm: _vm.clearOrder },
            model: {
              value: _vm.confirmDelete,
              callback: function ($$v) {
                _vm.confirmDelete = $$v
              },
              expression: "confirmDelete",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }