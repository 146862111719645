var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        transition: "slide-y-transition",
        "nudge-left": "40",
        "offset-y": "",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _vm._t(
                  "default",
                  function () {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { attrs: { icon: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                        1
                      ),
                    ]
                  },
                  { attrs: attrs, on: on }
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _vm.actions.length > 0
        ? _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { "max-width": "300" } },
            _vm._l(_vm.actions, function (action, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  attrs: { disabled: action.disabled },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.actionClicked(action)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-action",
                    { attrs: { disabled: action.disabled } },
                    [_c("v-icon", [_vm._v(_vm._s(action.icon))])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    { staticClass: "mr-3" },
                    [_c("v-list-item-title", [_vm._v(_vm._s(action.label))])],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "v-card",
            [
              _c("v-list-item", { staticClass: "font-italic" }, [
                _vm._v(" No actions available "),
              ]),
            ],
            1
          ),
      _vm.cancelDialog
        ? _c("CancelDialog", {
            attrs: { selectedOrder: _vm.selectedOrder },
            on: {
              cancel: _vm.cancel,
              closeCancelDialog: _vm.closeCancelDialog,
            },
            model: {
              value: _vm.cancelDialog,
              callback: function ($$v) {
                _vm.cancelDialog = $$v
              },
              expression: "cancelDialog",
            },
          })
        : _vm._e(),
      _vm.editNoteDialog
        ? _c("EditNoteDialog", {
            attrs: { selectedOrder: _vm.selectedOrder },
            on: {
              updateNote: _vm.updateNote,
              closeEditNoteDialog: _vm.closeEditNoteDialog,
            },
            model: {
              value: _vm.editNoteDialog,
              callback: function ($$v) {
                _vm.editNoteDialog = $$v
              },
              expression: "editNoteDialog",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }