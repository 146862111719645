var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        attrs: {
          height: _vm.tableSize,
          headers: _vm.computedHeaders,
          items: _vm.items,
          loading: _vm.loading,
          "item-key": "uuid",
          "hide-default-footer": "",
          "items-per-page": _vm.pageSize,
          "no-data-text": "Please enter search criteria",
          "fixed-header": "",
          dense: "",
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.pageSize = $event
          },
          "update:items-per-page": function ($event) {
            _vm.pageSize = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.sale_price`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "text-no-wrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.sale_price == 0
                            ? "-"
                            : `${
                                item.sale_price
                                  ? "$" + item.sale_price.toFixed(2)
                                  : "-"
                              }`
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.cost`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "text-no-wrap" }, [
                    _vm._v(
                      _vm._s(item.cost ? "$" + item.cost.toFixed(2) : "-")
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.history`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.getOrderHistory(item)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-history")])],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.add`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.addingItem || _vm.isOrderItem(item),
                        icon: "",
                        color: "success",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.add(item)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-plus-circle")])],
                    1
                  ),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("PagingFooter", {
                    attrs: {
                      pageSizes: _vm.pageSizes,
                      pageSize: _vm.pageSize,
                      page: _vm.page,
                      disablePrevious: _vm.disablePrevious,
                      disableNext: _vm.disableNext,
                    },
                    on: {
                      "update:pageSize": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function ($event) {
                        _vm.pageSize = $event
                      },
                      previous: _vm.previous,
                      next: _vm.next,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm.orderHistory
        ? _c("OrderHistoryModal", {
            attrs: { item: _vm.historyItem, party_id: _vm.storeId },
            on: { close: _vm.closeOrderHistory },
            model: {
              value: _vm.orderHistory,
              callback: function ($$v) {
                _vm.orderHistory = $$v
              },
              expression: "orderHistory",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }