<template>
    <v-menu transition="slide-y-transition" nudge-left="40" offset-y v-model="showMenu">
      <template v-slot:activator="{ on, attrs }">
        <slot :attrs="attrs" :on="on">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </slot>
      </template>
      <v-card v-if="actions.length > 0" class="mx-auto" max-width="300">
          <v-list-item v-for="(action, i) in actions" :key="i" :disabled="action.disabled"
            @click.stop="actionClicked(action)">
            <v-list-item-action :disabled="action.disabled">
              <v-icon>{{ action.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class=" mr-3">
              <v-list-item-title>{{ action.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-card>
      <v-card v-else>
        <v-list-item class="font-italic">
          No actions available
        </v-list-item>
      </v-card>
      <CancelDialog
        v-if="cancelDialog"
        v-model="cancelDialog"
        :selectedOrder="selectedOrder"
        @cancel="cancel"
        @closeCancelDialog="closeCancelDialog">
      </CancelDialog>
      <EditNoteDialog
      v-if="editNoteDialog"
        v-model='editNoteDialog'
        :selectedOrder='selectedOrder'
        @updateNote="updateNote"
        @closeEditNoteDialog='closeEditNoteDialog'
      >
      </EditNoteDialog>
    </v-menu>
  </template>
  <script>
  // api
  import ProductOrder from '@/api/product-order'
  // mixins
  import { displayAlert } from '@/mixins/alert'
  import { userAccess } from '@/mixins/user-access'
  // components
  import CancelDialog from '@/components/product-orders/CancelDialog.vue';
  import EditNoteDialog from '@/components/product-orders/EditNoteDialog.vue';
  export default {
    name: 'ProductOrderActions',
    mixins: [userAccess, displayAlert],
    components: {CancelDialog, EditNoteDialog},
    props: [
      'selectedOrder'
    ],
    data() {
      return {
        showMenu: false,
        cancelDialog: false,
        editNoteDialog: false
      }
    },
    computed: {
      actions() {
        return [
          { label: 'Cancel', value: 'cancel', icon: 'mdi-cancel', disabled: false, show: this.selectedOrder.submitted && !this.selectedOrder.cancelled && this.selectedOrder.product_order_type != "Item" },
          { label: this.selectedOrder.note ? 'Edit Note' : 'Add Note', value: 'note', icon: 'mdi-note-text-outline', disabled: false, show: this.selectedOrder.cancelled && this.isAdmin }
        ].filter(action => action.show)
      }
    },
    methods: {
      actionClicked(action) {
        switch (action.value) {
          case 'cancel':
            this.cancelDialog = true
            this.showMenu = false
            break
          case 'note':
            this.editNoteDialog = true
            this.showMenu = false
            break
        }
      },
      async cancel (orderNote) {
        try {
          const params = this.selectedOrder
          params.cancelled= true
          params.note = orderNote
          await ProductOrder.updateProductOrder(params, this.selectedOrder.id)
          this.$emit('getOrders')
        } catch (err) {
          this.handleError(err)
        }
      },
      async updateNote (orderNote) {
        try {
          const params = this.selectedOrder
          params.note = orderNote,
          await ProductOrder.updateProductOrder(params, this.selectedOrder.id)
          this.$emit('getOrders')
        } catch (err) {
          this.handleError(err)
        }
      },
      closeCancelDialog () {
        this.cancelDialog = false
      },
      closeEditNoteDialog () {
        this.editNoteDialog = false
      }
    }
  }
  </script>