var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, persistent: "", width: "400" },
      on: {
        "click:outside": function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "fill" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-card-title",
                { staticClass: "my-0 py-0 pt-0 mb-2 heading-6" },
                [
                  _c("div", { staticClass: "text-no-wrap" }, [
                    _vm._v(" Item ID: " + _vm._s(_vm.item.item_id) + " "),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dense: "" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-subtitle", [
                _vm._v(" " + _vm._s(_vm.item.description) + " "),
              ]),
              _vm.loading
                ? [
                    _c("v-progress-linear", {
                      attrs: {
                        indeterminate: "",
                        color: "primary",
                        height: "6",
                      },
                    }),
                  ]
                : [
                    _c(
                      "v-row",
                      {
                        staticClass: "header mx-1 py-1",
                        attrs: { dense: "", align: "center" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-2 ml-0",
                            attrs: { align: "start" },
                          },
                          [_vm._v(" Submitted On ")]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pl-0 mr-2", attrs: { cols: "auto" } },
                          [_vm._v(" Qty ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "mx-1 pb-1", attrs: { dense: "" } },
                      [
                        _vm.historyItems.length > 0
                          ? _c(
                              "v-list",
                              {
                                staticClass: "pt-0",
                                staticStyle: {
                                  "max-height": "60vh",
                                  "overflow-y": "auto",
                                },
                                attrs: { dense: "", width: "100%" },
                              },
                              [
                                _vm._l(
                                  _vm.historyItems,
                                  function (historyItem, index) {
                                    return [
                                      _c(
                                        "div",
                                        { key: historyItem.product_order_id },
                                        [
                                          _c(
                                            "v-list-item",
                                            { staticClass: "ma-0 pl-2 py-1" },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "pl-0 ml-0",
                                                  attrs: {
                                                    dense: "",
                                                    align: "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pl-0 ml-0",
                                                      attrs: { align: "start" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$config.formatTimestamp(
                                                              historyItem.submitted_on
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pl-0 mr-2",
                                                      attrs: { cols: "auto" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            historyItem.quantity
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          index < _vm.historyItems.length - 1
                                            ? _c("v-divider")
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _c(
                              "v-row",
                              { staticClass: "my-4", attrs: { dense: "" } },
                              [
                                _c("v-col", { attrs: { align: "center" } }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-light text-no-wrap",
                                    },
                                    [_vm._v(" No previous orders. ")]
                                  ),
                                ]),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }